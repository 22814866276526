<template>
    <div class="calender-date wrapper d-flex scroll-hide box-shadow">
        <div class="date-item hand" :class="[selectedDateIndex == i ? 'active' : '']" v-for="(item, i) in weekArray" :key="i" @click="actSelectedDate(item, i)">
            <p :class="item.displayName && 'today'">{{ item.displayName || item.dayName }}</p>
            <br v-if="!item.displayName"/>
            <p v-if="!item.displayName">{{ item.date }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CallenderContainer',
    data() {
        return {
            selectedDateIndex: 3,
            weekArray: [],
        }
    },
    created() {

    },
    mounted() {
        this.getWeek();
    },
    methods: {
        getWeek() {
            // Get the current date
            const currentDate = new Date();

            // Initialize an empty array to store the dates
            this.weekArray = [];

            // Generate three days before the current date
            for (let i = 3; i > 0; i--) {
                const previousDate = new Date(currentDate);
                previousDate.setDate(currentDate.getDate() - i);
                this.weekArray.push({
                    date: previousDate.toISOString().split('T')[0],
                    dayName: previousDate.toString().split(' ')[0]
                });
            }

            // Add the current date
            this.weekArray.push({
                date: currentDate.toISOString().split('T')[0],
                dayName: currentDate.toString().split(' ')[0],
                displayName: 'Today'
            });

            // Generate six days after the current date
            for (let i = 1; i <= 6; i++) {
                const nextDate = new Date(currentDate);
                nextDate.setDate(currentDate.getDate() + i);
                this.weekArray.push({
                    date: nextDate.toISOString().split('T')[0],
                    dayName: nextDate.toString().split(' ')[0]
                });
            }
        },
        actSelectedDate(date, i) {
            this.selectedDateIndex = i;
            this.$emit("actSelectDate", date.date);
        },
    }
}
</script>
<style scoped lang="scss">
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";
    .calender-date{
        font-size: 0.8rem;
        text-align: center;
        border-bottom: 1px solid #ccc; 
        line-height: 1em;
        overflow: auto;
        white-space: nowrap;
        .date-item:not(:last-child){
            border-right: 2px solid #ccc;;
        }
        .date-item {
            padding: 1rem;
            align-content: center;
            &:hover {
                background-color: #707070;
                color: $clr-create-main-txt !important;
            }
            .today {
                font-weight: bold;
            }
        }

    }
    @media screen and (min-width: 1024px) {
        .calender-date{
            display: flex;
            width: 100%;
            height: 100px;
            align-items: center;
            .date-item {
                width: calc(100% / 8);
                height: 100%;
                align-content: center;
            }
        }
    }
    .active { 
        background: $primary-btn-color;
        color: #FFFFFF !important;
    }
    .box-shadow {    
        box-shadow: 1px 0px 5px 1px #ddd;
    }

</style>